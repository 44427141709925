.about-container {
    background-color: #121212;
    padding: 1rem;
    border-radius: 1rem;
    max-height: 275px;

    .about-me {
        text-align: left;
        color: #ffffff;
        opacity: 0.7;

        span {
            color: #ffffff;

        }
    }

    .about-heading {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-align: left;
        color: #FFFFFF;
        margin-top: 0.5rem;
    }

    .location {
        text-align: left;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        opacity: 0.7;

    }

    .location p {

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        // opacity: 0.7;

    }

    .youmightLike {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-align: left;
        color: #FFFFFF;
    }
}
