.feed-page {
   
//   .feed {
//     border: "1px solid #000";
//     color: "#fff";
   
//     display: "block";
//     padding: "0";
//   }
   
  .menu-btn {
    min-width: 137.06px;
    min-height: 43.77px;
    border-radius: 6px;
    border: none;
  }
  .active-menu {
    background-color: #ff4d1d !important;
  }

  .active {
    border: 3px solid #d1d1d1 !important;
    background: transparent !important;
    color: #fff;
    box-shadow: none !important;
    font-size: 20px !important;
  }

  /* styles.css */
.post-form {
  width: 100%;
  margin-bottom: 20px;
}

textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.emoji-button{
  border: none;
  background: transparent;
}

.emoji-container{
  margin-top: 0rem;
}
.create-post {
  padding: 10px 20px;
  margin: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.bg-white {
  background-color: #fff;
}

.rounded-lg {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem
}

.emoji-container{
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem
}
.flex {
  display: flex;
}

.items-center {
  // align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.p-4 {
  padding: 1rem;
}

.avatar-container {
  width: 3rem;
  height: 3rem;
}

.avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.name-container {
  margin-left: 1rem;
}

.font-bold {
  font-weight: bold;
}

.bg-gray-500{
  background-color: rgb(120, 108, 108);
}
.text-white {
  color: #ffffff;
}

.text-gray-500 {
  color: #6b7280;
}

.text-area-container {
  width: 80%;
  position: relative;
}

.bg-black {
  background-color: #000;
}

.text-white {
  color: #fff;
}

.w-full {
  width: 100%;
}

.textarea {
  border: 1px solid black;
  min-height: 200px;
}

.absolute {
  position: absolute;
}

.bottom-4 {
  bottom: 1rem;
}

.right-4 {
  right: 1rem;
}
  .hide-field{
    display: none;
  }

  .thumbnail { 
    width: 4rem;
    height: 4rem;
    border-radius: 0.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .thumbnail-container {
    position: relative;
    display: inline-block;
  }
  
  .thumbnail {
    width: 50px; /* Set width and height according to your thumbnail size */
    height: 50px;
    border: 1px solid #ccc;
    overflow: hidden;
    // margin-right: 10px;
  }
  
  .close-icon {
    position: absolute;
    top: 5px;
    left: 0px;
    font-size: 20px;
    cursor: pointer;
    z-index: 10;
  }
}

.react-rte-demo{
  background-color: red;
}

.public-DraftEditor-content{
  background-color: rgb(120, 108, 108) !important;
  color: white !important;

}

.RichTextEditor__root___2QXK-{
  background-color: transparent !important;
}

.ButtonGroup__root___3lEAn>div:first-child>button{
  background: transparent !important;
}

.ButtonGroup__root___3lEAn>div{
  background: transparent !important;
}

.ButtonGroup__root___3lEAn>div+div>button{
  background: transparent !important;
}
