.layout-page {
    .child-conatiner {
        height: calc(100vh - 55px);
        overflow-x: scroll;
        width: 100%;
  background-color: var(--bg-primary);
        color: var(--text-primary);
    }
}
.active-menu {
    background-color: #ff4d1d !important;
//   color: #ff4d1d  !important;
}
.sidebar {
    height: calc(100vh - 55px);
    background-color: #252525;
    .css-1604tlu {
        border: #252525;
        height: 100%;
    }
    
    .css-dip3t8 {
        // width: 200px;
        z-index: 9999;
        margin-right: 0!important;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        color: #fff;
        background: #252525;
        .css-1wdg6ox:last-child {
            margin-top: 100px;
            a  {
                .css-wx7wi4 {
                order: 1
                }
            }
        }
        .ps-menu-button {
            &:hover {
                background-color: #ff4d1d;
            }
        }
        .ps-menu-icon {
            img {
                width: 18px;
            }
            i {
                color: #fff;
            }
        }
        .css-12w9als {
            color: #fff;
            font-weight: normal;
            font-size: 15px;
            text-align: left;
            margin-left: 15px;
           
        }
        .css-z5rm24 {
            background: #252525;
            .ps-menu-button {
                padding-right: 0px;
                padding-left: 0px;
            }
        }
    }

}
.sidebar .ps-submenu-content {
    background-color: #252525 !important;
}