.post-creation-box {
    background-color: #3a3636;
    padding: 15px;
    border-radius: 12px;
    border: 2px solid #ffffff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    margin-top: 30px;
  }
  
  .post-textarea {
    width: 100%;
    border: 0px;
    padding: 10px;
    font-size: 16px;
    resize: none;
    margin-bottom: 10px;
    margin-top: 10px;
    background-color: #3a3636;
    outline: none;
    color: white;
  }

  .post-textarea:active {
    border: 0px;
  }
  
  .post-textarea:focus-visible {
    border: 0px;
  }

  // textarea {
  //   border: none;
  //   outline: none; /* This removes the default focus outline */
  // }
  

  .post-btn {
    background-color: #1877f2;
    color: white;
    border: none;
    padding: 8px 16px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .post-btn:hover {
    background-color: #166fe5;
  }
  
