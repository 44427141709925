.home-page {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#363636+0,000000+70 */
  background: var(--background) !important;
  /* Old browsers */
  background: -moz-linear-gradient(top, #363636 0%, #000000 70%);
  /* FF3.6-15 */
  color: var(--text-primary) !important;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #363636),
    color-stop(70%, #000000)
  );
  background-color: var(--bg-primary);
  /* Chrome4-9,Safari4-5 */
  background: -webkit-linear-gradient(top, #363636 0%, #000000 70%);
  /* Chrome10-25,Safari5.1-6 */
  background: -o-linear-gradient(top, #363636 0%, #000000 70%);
  /* Opera 11.10-11.50 */
  background: -ms-linear-gradient(top, #363636 0%, #000000 70%);
  /* IE10 preview */
  background: linear-gradient(to bottom, #363636 0%, #000000 70%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#363636', endColorstr='#000000', GradientType=0);
  /* IE6-9 */
  /* overflow: auto; */
  /* height: '100%'; */

  .logo-row {
    margin-top: 10%
  }
  .logo-img {
    width: 30%;
  }

  .sign-in-btn {
    width: 301px;
    height: 52px;
    left: 809px;
    top: 744px;
  
    background: #0097FF;
    border-radius: 9px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  
    align-items: center;
    text-align: center;
    letter-spacing: -0.41px;
  
    color: #E9E9E9;
  }

  .white-text {
    color: #f9f9f9;
    font-weight: 700;
}
@media (max-width: 480px)  {
  .logo-img {
    width: 80%;
  }
  .logo-row {
    margin-top: 25%
  }
}
}