@font-face {
  font-family: 'ChorusX-icons';
  src:  url('fonts/ChorusX-icons.eot?8xmhw2');
  src:  url('fonts/ChorusX-icons.eot?8xmhw2#iefix') format('embedded-opentype'),
    url('fonts/ChorusX-icons.ttf?8xmhw2') format('truetype'),
    url('fonts/ChorusX-icons.woff?8xmhw2') format('woff'),
    url('fonts/ChorusX-icons.svg?8xmhw2#ChorusX-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ChorusX-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Menu-Create:before {
  content: "\e900";
  color: #f9f9f9;
}
.icon-Menu-Friends:before {
  content: "\e901";
  color: #f9f9f9;
}
.icon-Menu-Home:before {
  content: "\e902";
  color: #f9f9f9;
}
.icon-Menu-Map:before {
  content: "\e903";
  color: #f9f9f9;
}
.icon-Menu-Market:before {
  content: "\e904";
  color: #f9f9f9;
}
.icon-Menu-Play:before {
  content: "\e905";
  color: #f9f9f9;
}
.icon-Menu-Settings:before {
  content: "\e906";
  color: #f9f9f9;
}
.icon-Menu-Userprofile:before {
  content: "\e907";
  color: #f9f9f9;
}
.icon-Menu-Wallet:before {
  content: "\e908";
  color: #f9f9f9;
}
