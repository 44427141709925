.register-page {
    .logo-img {
        width: 40%;
    }
    .fields-container {
        width: 50%;
        @media (max-width: 450px) {
            width: 100%;
          }
    }
    .box-line {
        border-radius: 0;
        padding: 0;
        border: transparent;
        background: transparent;
        color: #f9f9f9;
        border-bottom: 1px solid;
        border-color: transparent;
        border-bottom-color: hsla(0,0%,97.6%,.5019607843137255);
        padding-bottom: 8px;
        color: #FFFFFF;
        &:focus {
            background-color: transparent;
            border-color: none;
            box-shadow: none;
            color: #FFFFFF;
        }
    }
    .form-select {
        background: transparent;
        color: #ffff;
    }
    .signInBtn {
        width: 301px;
        height: 52px;
        left: 809px;
        top: 744px;
      
        background: #0097FF;
        border-radius: 9px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
      
        align-items: center;
        text-align: center;
        letter-spacing: -0.41px;
      
        color: #E9E9E9;
      }
}