.services-page {
    .service-btn {
        background: linear-gradient(260.02deg, #0097FF 2.67%, #006BFF 33.44%, #003885 92.93%) !important;
        color: #FFFFFF;
        border-radius: 17px;
        padding: 50px 0px 50px 0px;
        .label-text {
            font-size: 36px;
            font-family: 'Michroma', sans-serif;
            text-shadow: 2px 0 #00000040, -2px 0 #00000040, 0 2px #00000040, 0 -2px #00000040,
                       1px 1px #00000040, -1px -1px #00000040, 1px -1px #00000040, -1px 1px #00000040;
          }
          img {
            width: 60px;
            height: 60px;
          }
          &:hover {
                border: 3px solid #0097FF;
          }
    }
    
}