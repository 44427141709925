.edit-profile-container {

    .profile-image {
        position: absolute;
        top: 2rem;
        left: 50%;
        transform: translateX(-50%);
        max-width: 150px;
        z-index: 1;
    }

    .edit-icon-profile {
        position: absolute;
        z-index: 1;
        top: 8rem;
    }

    .edit-icon-cover {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    input,
    textarea,
    select {
        border: 1px solid #C29B0C8C !important;
        color: #C29B0C8C;
    }

    ::placeholder {
        color: #C29B0C8C !important;
        opacity: 1;
    }

    .fields {
        background-color: transparent !important;
        padding: 1rem !important;
        color: #C29B0C8C !important;
    }

    .field-border {
        border: 1px solid #C29B0C8C !important;
    }

    input:focus {
        border: 1px solid #C29B0C8C !important;

    }

    .label-color {
        color: #C29B0C8C !important;
    }

    .ms-2 {
        left: 28rem;
        margin-top: -10px !important;
    }

    .ms-2 span {
        padding: 0 2rem;
    }

    .update-location {
        background-color: #C29B0C;
        border: 1px solid #C29B0C;
        padding: 1rem;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #000000;
    }

    .update-location:hover {
        background-color: transparent;
        border: 1px solid #C29B0C;
        color: #C29B0C;
    }

    .update-footer {
        display: flex !important;
        flex-direction: row-reverse !important;
    }

    .opacity-loading {
        opacity: 0.7;
    }

}
