.custom-bar {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
  }
  
  .left-icons {
    display: flex;
  }
  
  .icon {
    color: white;
    font-size: 24px;
    margin-right: 10px;
  }
  
  .post-button {
    background: linear-gradient(to bottom, #FF8400, rgba(255, 132, 0, 0.8), rgba(255, 132, 0, 0.6), rgba(245, 123, 8, 0.4), rgba(241, 122, 4, 0.618), rgba(251, 131, 3, 0.635));
    color: white;
    border: none;
    padding: 8px 16px;
    font-size: 16px;
    border-radius: 12px;
    cursor: pointer;
  }

  .post-button:hover {
    background-color: #fafcfd7e;
  }
  
