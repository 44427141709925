.edit-profile-page {
    .edit-btn {
        bottom: 0;
        right: 11px;
        width: 30px;
        height: 30px;
        background: #ff9601;
        border-radius: 50%;
        color: #fff;
        font-size: 13px;
        padding: 8px 8px 4px 0px;
    }
    .banner-img {
        width: 100%;
        height: 250px;
        object-fit: cover;
    }
    .profile-img{
        border-radius: 50%;
        width: 255px;
        height: 255px;
        -o-object-fit: fill;
        object-fit: fill;
        margin-top: -115px;
    }
    .fields-container {
        width: 60%;
        @media (max-width: 450px) {
            width: 100%;
          }
          .box-line {
            border-radius: 0;
            padding: 0;
            width: 100%;
            border: transparent;
            background: transparent;
            color: #f9f9f9;
            border-bottom: 1px solid;
            border-color: transparent;
            border-bottom-color: hsla(0,0%,97.6%,.5019607843137255);
            padding-bottom: 8px;
            color: #FFFFFF;
            &:focus {
                background-color: transparent;
                border-color: none;
                box-shadow: none;
                color: #FFFFFF;
            }
          }
    }
}