.user-list-container {
    background-color: transparent !important;

    .card {
        background-color: transparent !important;
    }
}

.text-white {
    color: white !important;
}
.text-white-opacity {
    color: white !important;
    opacity: 0.7;
}
