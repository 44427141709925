.statistics-page {
    .stat-box {
        /* position: absolute; */
      background: #36262C;
      border: 9px solid #4E00CF;
      border-radius: 13px;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 0%;
      height: fit-content;
      /* or 0px */
      align-items: center;
      
      color: #E9E9E9;
      /* margin-left: 20%; */
      }
    .value-row {
        margin-top: 32px;
        width: 100%;
        cursor: pointer;
        word-break: break-all;
        text-overflow: ellipsis;
      }
      .current-link{
          color: #ff4d1d;
      }
      .count-value {
        margin-top: 36px;
      }
      .entertainer-list {
        padding: 15px;
      }
      .list-head {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 0%;
        /* or 0px */
        
        display: flex;
        align-items: center;
        
        color: #F1F1F1;
        
        }
        
        .list-item {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 0%;
        /* or 0px */
        align-items: center;
        letter-spacing: 0.26em;
        
        color: #F1F1F1;
        }
        .chart-container {
            width: calc(100vh - 26vh);
            height: 70vh;
      }
      .head-title{
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        /* or 0px */
        color: #F1F1F1 !important;
        
        text-shadow: 6px 6px 11px rgba(0, 0, 0, 0.3);
        display: inline-grid;
        
        }
        .divider1 {
            height: 5px;
            background: #4E00CF;
            border-radius: 7px;
        
        }
      
}