.createContainer {
  background-color: var(--bg-primary);
}
.text {
  color: va(--text-primary);
  margin-top: 55px;
}

.create-btn,
.edit-btn {
  margin: 50px;
  width: 180px;
  height: 75px !important;
  padding: 15px !important;
  font-size: 25px !important;
  border-radius: 25px !important;
}
.edit-btn{
    margin-left: 20px;
}
.create-entity,
.create-project,
.create-content,
.create-event,
.edit-entity,
.edit-project,
.edit-content,
.edit-event {
  padding: 10px;
  display: block;
  margin: 20px;
  border-radius: 20% !important;
  font-size: 24px !important;
}
.create-section,.edit-section {
  display: flex;
  flex-direction: column;
  width: 230px;
  margin: 0 auto;
  padding: 20px;
  
}
