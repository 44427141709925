@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "rogueland_freeregular";
  src: url("../Fonts/ncs_rogueland-webfont.woff2") format("woff2"),
    url("../Fonts/ncs_rogueland-webfont.woff") format("woff");
  font-style: normal;
}

/* @font-face {
  font-family: "ChorusX-icons";
  src: url("../icons/Johnson/fonts/ChorusX-icons.eot?8xmhw2");
  src: url("../icons/Johnson/fonts/ChorusX-icons.eot?8xmhw2#iefix")
      format("embedded-opentype"),
    url("../icons/Johnson/fonts/ChorusX-icons.ttf?8xmhw2") format("truetype"),
    url("../icons/Johnson/fonts/ChorusX-icons.woff?8xmhw2") format("woff"),
    url("../icons/Johnson/fonts/ChorusX-icons.svg?8xmhw2#ChorusX-icons")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
} */

/* [class^="icon-"],
[class*=" icon-"] { */
/* use !important to prevent issues with browser extensions that change fonts */
/* font-family: "ChorusX-icons" !important;
  speak: never; */

/* font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1; */
/* Better Font Rendering =========== */
/* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */


.icon:hover {
  color: #ff512f;
}

.icon-Menu-Create:before {
  content: "\e900";
}

.icon-Menu-Friends:before {
  content: "\e901";
}

.icon-Menu-Home:before {
  content: "\e902";
}

.icon-Menu-Map:before {
  content: "\e903";
}

.icon-Menu-Market:before {
  content: "\e904";
}

.icon-Menu-Play:before {
  content: "\e905";
}

.icon-Menu-Settings:before {
  content: "\e906";
}

.icon-Menu-Userprofile:before {
  content: "\e907";
}
.icon-Menu-Userprofile {
  font-size: 20px;
  position: relative;
  top: -2px;
}
.icon-Menu-Chat-Notification:before {
  content: "\f075";
}

.icon-Menu-Wallet:before {
  content: "\e908";
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

#app {
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
}
.content-div {
  margin-top: 50px;
}

.main-content-new.lightbg {
  background-image: url("../content-page.jpg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}
.main-content.lightbg {
  background-image: url("../lightbg.svg.svg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.main-content-new.plbg {
  background-image: url("../content-page.jpg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
}
.main-content-event.plbg {
  background-image: url("../events-page.jpg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
}
.main-content-event.lightbg {
  background-image: url("../events-page.jpg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
}

.main-content.wabg {
  background-image: url("../comingsoon/Wallet.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
}

.main-content.pabg {
  background-image: url("../comingsoon/Marketplace.png") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
}
.main-content.plbg {
  background-image: url("../comingsoon/Mediaplayer.png") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
}
.main-content.homebg {
  background-image: url("../comingsoon/home2.png") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
}
.main-content.premiumbg {
  background-image: url("../bg-premium.png") !important;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.main-content.premiumbg1 {
  background-image: url("../bg-premium.png") !important;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.main-content.prbg {
  background-image: url("../comingsoon/contentcreation.png");
  /* background-repeat: no-repeat; */
  background-size: cover;
  background-position: center;
  /* width: 100vw; */
  height: 100vh;
}
.register-form {
  display: inline-block;
  width: 500px;
  /* margin-top: 50px; */
  max-width: 90%;
}
.vsm--toggle-btn {
  position: relative;
  top: -2px;
}
#toggle {
  position: relative;
  /* top: 5px; */
}
.whitetext {
  color: #f9f9f9;
  font-weight: 800;
}

.colored-text {
  color: #2c3e50;
}

/*  Additional css */
.v_input-wrap {
  /* position: fixed; */
  bottom: 50px;
  left: 0;
  right: 0;
}

.v_input-inner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  background: #fff;
  height: auto;
  overflow: auto;
}

.v_input-inner .v-input,
.v_input-inner .v-input__slot {
  margin: 0;
}

.main-content {
  /* height: 100vh; */
}

.navbar {
  justify-content: center;
}

.navbar a {
  text-decoration: none;
  font-size: 22px;
}

button#logout-button {
  color: #fff;
  text-decoration: none;
  font-size: 22px;
}

a:hover {
  text-decoration: none;
}

a {
  text-decoration: none;
}

button#logout-button {
  color: #fff;
  text-decoration: none;
  font-size: 22px;
  background-color: transparent;
  padding: 0;
  border-width: 0;
}

.logo img {
  width: 100px;
}

.bottom-menu {
  padding: 5px;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff6800+0,ffbd60+100 */
  background: #ff6800;
  /* Old browsers */
  background: -moz-linear-gradient(left, #ff6800 0%, #ffbd60 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #ff6800 0%, #ffbd60 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: #252525;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff6800', endColorstr='#ffbd60', GradientType=1);
  /* IE6-9 */
}

.navbar ul {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 0 !important;
}

.navbar ul li {
  flex: auto;
}

li.disbale a {
  pointer-events: none;
  opacity: 0.7;
}

/* .btn-primary {
  color: #fff;
  background-color: #ff6800;
  border-color: #ff6800;
}
.btn-primary:hover {
  color: #fff;
  background-color: #ff6800;
  border-color: #ff6800;
} */
.modal-content {
  background-color: transparent !important;
}
.modal-header {
  border-bottom: none !important;
}
.modal-header > .close {
  color: #fff !important;
}
.theme--dark.v-overlay {
  display: none !important;
}

.btn-primary {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: #f9f9f9;
  border-radius: 10px;
  display: block;
  font-weight: 600;
  border-color: transparent;
  background-color: transparent;
  font-size: 20px;
  /* background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #f09819 51%,
    #ff512f 100%
  ); */
  /* background: linear-gradient(180deg, #003885, #0097FF) !important; */
  background: linear-gradient(180deg,  #0097FF, #003885) !important;

}

.btn-primary:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #f9f9f9;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
}

.user-head {
  display: flex;
  position: relative;
  /* align-items: center; */
  margin-top: 5px;
  margin-left: -10px;
}

.user-head img {
  border-radius: 50%;
  width: 120px;
}

.user-detail-txt {
  /* margin-bottom: 10px; */
}
.friend-details span {
  margin-right: 10px;
  font-size: 12px;
  font-weight: 700;
  color: #565656;
}
.friend-details span span {
  font-size: 14px;
  color: #707074;
  margin-right: 7px;
}
.user-box {
  padding: 20px;
  text-align: left;
  /* margin-top: 40px; */
}

.user-detail p {
  margin: -5px 0 0px;
  width: auto;
}

.user-detail {
  padding: 0px 5px 0px 5px;
  color: #fff;
}

.user-description {
  margin-top: 30px;
}

.user-links a {
  display: inline-block;
  margin-right: 5px;
  color: #a5a5a5;
  font-size: 18px;
}
.user-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  /* display: flex; */
  align-items: center;
  letter-spacing: -0.41px;
  color: #e9e9e9;
}

.gm-style-iw-tc {
  /* display: none; */
}
.landing-screen {
  display: flex;
  align-items: center;
  height: 100vh;
  background: #363636;
  /* Old browsers */
  background: -moz-linear-gradient(top, #363636 0%, #000000 70%);
  /* FF3.6-15 */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #363636),
    color-stop(70%, #000000)
  );
  /* Chrome4-9,Safari4-5 */
  background: -webkit-linear-gradient(top, #363636 0%, #000000 70%);
  /* Chrome10-25,Safari5.1-6 */
  background: -o-linear-gradient(top, #363636 0%, #000000 70%);
  /* Opera 11.10-11.50 */
  background: -ms-linear-gradient(top, #363636 0%, #000000 70%);
  /* IE10 preview */
  background: linear-gradient(to bottom, #363636 0%, #000000 70%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#363636', endColorstr='#000000', GradientType=0);
  /* IE6-9 */
}

.landing-screen:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.landing-box {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 0 30px;
  position: relative;
}

.landing-box img {
  /* width: 130px; */
}

.home-btns a .btn {
  /* width: 25%; */
  margin-top: 25px;
  height: 45px;
  border-radius: 10px;
  text-decoration: none;
  color: #f9f9f9;
}

.btn.btn-default {
  background: #ddd;
  color: #ff9600;
}

.main-content {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#363636+0,000000+70 */
  background: var(--background) !important;
  /* Old browsers */
  background: -moz-linear-gradient(top, #363636 0%, #000000 70%);
  /* FF3.6-15 */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #363636),
    color-stop(70%, #000000)
  );
  /* Chrome4-9,Safari4-5 */
  background: -webkit-linear-gradient(top, #363636 0%, #000000 70%);
  /* Chrome10-25,Safari5.1-6 */
  background: -o-linear-gradient(top, #363636 0%, #000000 70%);
  /* Opera 11.10-11.50 */
  background: -ms-linear-gradient(top, #363636 0%, #000000 70%);
  /* IE10 preview */
  background: linear-gradient(to bottom, #363636 0%, #000000 70%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#363636', endColorstr='#000000', GradientType=0);
  /* IE6-9 */
  /* overflow: auto; */
  /* height: '100%'; */
}
.header.map.justify-content-center.d-flex {
  background-color: #252525;
}
.vsm--toggle-btn {
  background-color: var(--sidebar-background) !important;
}
.main-content.register-screen {
  /* padding-top: 50px; */
  padding-top: 0px;
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 120vh !important;
  overflow: auto;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#363636+0,000000+70 */
  background: #363636;
  /* Old browsers */
  background: -moz-linear-gradient(top, #363636 0%, #000000 70%);
  /* FF3.6-15 */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #363636),
    color-stop(70%, #000000)
  );
  /* Chrome4-9,Safari4-5 */
  background: -webkit-linear-gradient(top, #363636 0%, #000000 70%);
  /* Chrome10-25,Safari5.1-6 */
  background: -o-linear-gradient(top, #363636 0%, #000000 70%);
  /* Opera 11.10-11.50 */
  background: -ms-linear-gradient(top, #363636 0%, #000000 70%);
  /* IE10 preview */
  background: linear-gradient(to bottom, #363636 0%, #000000 70%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#363636', endColorstr='#000000', GradientType=0);
  /* IE6-9 */
  /* margin-top: 50px; */
}

.main-content.register-screen:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120vh;
}

.main-content.login-screen {
  /* padding-top: 100px; */
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#363636+0,000000+70 */
  background: #363636;
  /* Old browsers */
  background: -moz-linear-gradient(top, #363636 0%, #000000 70%) !important;
  /* FF3.6-15 */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #363636),
    color-stop(70%, #000000)
  ) !important;
  /* Chrome4-9,Safari4-5 */
  background: -webkit-linear-gradient(top, #363636 0%, #000000 70%) !important;
  /* Chrome10-25,Safari5.1-6 */
  background: -o-linear-gradient(top, #363636 0%, #000000 70%) !important;
  /* Opera 11.10-11.50 */
  background: -ms-linear-gradient(top, #363636 0%, #000000 70%) !important;
  /* IE10 preview */
  background: linear-gradient(to bottom, #363636 0%, #000000 70%) !important;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#363636', endColorstr='#000000', GradientType=0);
  /* IE6-9 */
}

.main-content.login-screen:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.header {
  position: relative;
  background-color: transparent;
}

.register-form {
  position: relative;
}

.headerbox {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#363636+0,000000+70 */
  background: #363636;
  /* Old browsers */
  background: -moz-linear-gradient(top, #363636 0%, #000000 70%);
  /* FF3.6-15 */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #363636),
    color-stop(70%, #000000)
  );
  /* Chrome4-9,Safari4-5 */
  background: -webkit-linear-gradient(top, #363636 0%, #000000 70%);
  /* Chrome10-25,Safari5.1-6 */
  background: -o-linear-gradient(top, #363636 0%, #000000 70%);
  /* Opera 11.10-11.50 */
  background: -ms-linear-gradient(top, #363636 0%, #000000 70%);
  /* IE10 preview */
  background: linear-gradient(to bottom, #363636 0%, #000000 70%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#363636', endColorstr='#000000', GradientType=0);
  /* IE6-9 */
}

.google-map {
  display: none;
}

.map-view {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.register-form .btn {
  width: 100%;
  color: #f9f9f9;
}

.register-form .multiselect__placeholder {
  color: #6e6e6e;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
  font-size: 1rem;
}

.map-marker {
  position: absolute;
  top: 30vh;
  left: 40%;
  z-index: 99;
}

.map-marker > img {
  width: 20px;
}
.user-tooltip {
  margin-top: 2px;
}

.user-tooltip .user-head img {
  border-radius: 50%;
  width: 50px;
}

.user-tooltip .user-detail h2 {
  padding: 10px;
}

.user-tooltip .user-detail p {
  /* font-size: 12px; */
  word-break: break-all;
}

.user-tooltip .user-detail {
  text-align: left;
}

.user-tooltip .user-detail a {
  color: inherit;
  text-decoration: none;
  font-size: 12px;
  box-shadow: unset;
  outline: unset;
}

.user-description h5 {
  font-weight: bold;
  font-size: 16px;
}

div.container {
  padding: 0px;
}

.interest-box li {
  margin-right: 2px;
  margin-left: 0;
  display: inline-block;
  background: #e5e5e5;
  font-size: 13px;
  padding: 1px 10px;
  border-radius: 5px;
  font-weight: 600;
}

.user-description p {
  font-size: 15px;
}

.chat-pop {
  position: fixed;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.chat-pop.hide {
  display: none;
}

.chat-head {
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  text-align: left;
  position: relative;
}

a.chat-close {
  color: inherit;
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 20px;
}

.chat-head h5 {
  font-weight: bold;
  font-size: 19px;
  margin-top: 3px;
  margin-bottom: 0;
}

.chat-pop-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px 20px;
  border-top: 1px solid #ddd;
}

.chat-pop-footer input {
  height: 37px;
  border: 1px solid #ddd;
  width: calc(100% - 47px);
}

.header.after-login .logo img {
  width: 35px;
  margin-bottom: 5px;
}

.header.after-login {
  position: relative;
  margin-top: 45px;
}

.back-link {
  position: absolute;
  left: 25px;
  font-size: 25px;
  top: 3px;
}

.hamburger-link {
  position: absolute;
  left: 25px;
  font-size: 25px;
  top: 45px;
}

.hamburger-link a {
  color: #f9f9f9;
}

.back-link a {
  color: #f9f9f9;
}

.user-action-links span {
  display: inline-block;
  background: #d9d9d9;
  color: #000;
  font-size: 12px;
  text-decoration: none;
  padding: 3px 5px;
  border-radius: 5px;
  font-weight: 600;
}

.user-action-links span:first-child {
  margin-right: 4px;
}

.user-action-links span:nth-child(2) {
  margin-right: 4px;
}
.circle-btn {
  border: 1px solid #ffffff;
  border-radius: 50%;
  /* padding: 3px 0px 2px 4px; */
  padding: 5px;
  text-align: center;
  margin-left: 5px;
  color: #fff;
  font-size: 12px;
}
.edit-profile {
  position: absolute;
  right: 25px;
  top: 3px;
}

.edit-profile a {
  font-size: 25px;
  color: #f9f9f9;
}

.profile {
  position: absolute;
  right: 25px;
  top: 45px;
}

.profile a {
  font-size: 25px;
  color: #f9f9f9;
}

.chat-notification {
  position: absolute;
  right: 60px;
  top: 45px;
}
.note {
  position: relative;
  /* top: 158px; */
}

.chat-notification div {
  font-size: 25px;
  color: #f9f9f9;
}

.map-marker.marker2 {
  bottom: 30vh;
  top: unset;
  left: 15%;
}

.map-marker.marker3 {
  top: unset;
  bottom: 10vh;
  right: 120px;
  left: auto;
}

.user-tooltip.hide {
  display: none;
}

span.away {
  display: inline-block;
  font-size: 11px;
}

.error {
  color: red;
}

span.connect-count {
  display: inline-block;
  font-size: 12px;
  background: green;
  color: #fff;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  text-align: center;
  padding-top: 2px;
}

.connects > span {
  font-size: 14px;
}

span.friend-connect {
  margin-right: 8px;
}

span.subscribe-connect span.connect-count {
  background: #e11e17;
}

textarea.form-control {
  height: auto;
  min-height: 150px;
}

.form-control {
  font-weight: 600;
  border-radius: 5px;
  color: #f9f9f9;
  background-color: transparent;
}

.form-control:focus {
  color: #f9f9f9;
  background-color: transparent;
  border-color: none;
}

input.form-control.box-line {
  border-radius: 0px;
  padding: 0px;
  border: transparent;
  background: transparent;
  color: #f9f9f9;
  border-bottom: 1px solid;
  border-color: transparent;
  border-bottom-color: #f9f9f980;
  padding-bottom: 8px;
}

input.formcontrol.box-line:focus {
  border: transparent;
  border-bottom: 1px solid;
  background: transparent;
  outline: transparent;
  box-shadow: none;
  color: #f9f9f9;
  border-bottom-color: #f9f9f980;
}

span.image-edit-icon {
  position: absolute;
  bottom: 0;
  right: 11px;
  width: 30px;
  height: 30px;
  background: #ff9601;
  border-radius: 50%;
  color: #fff;
  font-size: 13px;
  padding-top: 4px;
}

.delete-account {
  margin-top: 10px;
  display: inherit;
}

.delete-account input {
  margin-top: 10px;
  display: inherit;
}

span.add-friend:before {
  content: "\f234";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  /* margin-right: 5px; */
}

span.open-chat:before {
  content: "\f075";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  /* margin-right: 5px; */
}

span.envelope:before {
  content: "\f0e0";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  /* margin-right: 5px; */
}

span.unfriend:before {
  content: "\f4fc";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 5px;
}

span.subscribe-user:before {
  content: "\f0f3";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 5px;
}

span.unsubscribe-user:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 5px;
}

span.block-user:before {
  content: "\f3c1";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 5px;
}

span.unblock-user:before {
  content: "\f023";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 5px;
}

.Vue-Toastification__container .Vue-Toastification__toast {
  display: inline-flex;
  position: relative;
  max-height: 800px;
  min-height: 40px;
  padding: 10px 24px;
}

.Vue-Toastification__container.top-left,
.Vue-Toastification__container.top-right,
.Vue-Toastification__container.top-center {
  top: 50px !important;
}

.Vue-Toastification__container {
  width: 90vw !important;
  left: 5vw !important;
}

.gm-style .gm-style-iw-c {
  transform: translate3d(-50%, -60%, 0) !important;
}

.gm-style .gm-style-iw-t::after {
  top: 32px !important;
}

.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  box-shadow: unset;
  color: #fff;
  background-color: #ff6800;
  border-color: #ff6800;
}
.btn-link:active:focus {
  box-shadow: none !important;
}
.error.hide {
  display: none;
}

p.form-errors li {
  display: inline-block;
  width: 100%;
  color: red;
  text-align: left;
  margin: 0;
}

p.form-errors {
  text-align: left;
}

/* .item + .item:before {
  content: ",";
} */

.item span {
  border: 1px solid #fff;
  padding: 2px 15px 2px 15px;
  border-radius: 5px;
  font-size: 10px;
  color: #9fa3a7;
}

.user-about span {
  font-size: 12px;
  color: #dadddf;
}
.user-about {
  margin-bottom: 3px;
  /* width: 201px; */
  height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.user_input,
.location_input,
.events_input,
.stores_input,
.entertainers_input {
  /* color: #ff7433;
  font-family: "Roboto", sans-serif;
  font-weight: 900; */
  width: 224px;
  height: 31px;
  left: 1543px;
  top: 257px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 157%;
  /* or 31px */

  color: #0097FF !important;
}
.user_input.v-input--selection-controls__input input[role="checkbox"],
.v-input--selection-controls__input input[role="radio"],
.v-input--selection-controls__input input[role="switch"] {
  display: none;
}
.stores_input {
  color: #ff7433;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
}
.stores_input.v-input--selection-controls__input input[role="checkbox"],
.v-input--selection-controls__input input[role="radio"],
.v-input--selection-controls__input input[role="switch"] {
  display: none;
}
.location_input {
  color: #ff7433;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
}
.location_input.v-input--selection-controls__input input[role="checkbox"],
.v-input--selection-controls__input input[role="radio"],
.v-input--selection-controls__input input[role="switch"] {
  display: none;
}
.entertainers_input {
  color: #ff7433;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
}
.entertainers_input.v-input--selection-controls__input input[role="checkbox"],
.v-input--selection-controls__input input[role="radio"],
.v-input--selection-controls__input input[role="switch"] {
  display: none;
}
.viewer_input {
  /* color: white;
  font-family: "Roboto", sans-serif; */
  width: 173px;
  height: 25px;
  left: 1543px;
  top: 286px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 157%;
  /* or 25px */

  color: #e9e9e9;
}

.events_input {
  color: #ff7433;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
}

.acrobate_input {
  color: white;
  font-family: "Roboto", sans-serif;
}

.category-label {
  width: 173px;
  height: 25px;
  left: 1543px;
  top: 286px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 157%;
  color: #e9e9e9;
}

.affiliate_input {
  color: white;
  font-family: "Roboto", sans-serif;
}

.athlete_input {
  color: white;
  font-family: "Roboto", sans-serif;
}

.blogger_input {
  color: white;
  font-family: "Roboto", sans-serif;
}

.comedian_input {
  color: white;
  font-family: "Roboto", sans-serif;
}

.dancer_input {
  color: white;
  font-family: "Roboto", sans-serif;
}

.emcee_input {
  color: white;
  font-family: "Roboto", sans-serif;
}

.gamer_input {
  color: white;
  font-family: "Roboto", sans-serif;
}

.musician_input {
  color: white;
  font-family: "Roboto", sans-serif;
}

.painter_input {
  color: white;
  font-family: "Roboto", sans-serif;
}

.theater_input {
  color: white;
  font-family: "Roboto", sans-serif;
}

.writer_input {
  color: white;
  font-family: "Roboto", sans-serif;
}

/* 11-01-2022 style starts */
.img-icon {
  width: 50px;
  height: 50px;
}
.mini-toastr {
  right: 0px !important;
  left: 280px !important;
  top: 16px !important
}
#emoji-close-div {
  /* display: none !important; */
}
.mini-toastr .-error {
  font-family: 'Poppins' !important;
  padding: 2px;
  margin-left: 5px;
font-weight: 600 !important;
font-size: 12px !important;
  height: 23px !important;
  color: #E9E9E9 !important;
  background-color: #5C2B2B !important;
  border-radius: 4px !important;
  /* text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  display: flex !important; */
}

.mini-toastr .-success {
  font-family: 'Poppins' !important;
  padding: 2px;
  margin-left: 5px;
  font-weight: 600 !important;
  font-size: 12px !important;
  height: 23px !important;
  color: #E9E9E9 !important;
  background-color: #41533A !important;
  border-radius: 4px !important;
  /* text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  display: flex !important; */

}
.mini-toastr .-info {
  font-family: 'Poppins' !important;
  padding: 2px;
  margin-left: 5px;
  font-weight: 600 !important;
  font-size: 12px !important;

  height: 23px !important;
  color: #E9E9E9 !important;
  background-color: #41533A !important;
  border-radius: 4px !important;
  /* text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  display: flex !important; */
}
.category-wrap .v-application--wrap {
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  min-height: unset;
  background-color: transparent;
  text-align: left;
}
.theme--light.v-navigation-drawer {
  background-color: transparent !important;
}
.locationSwitch {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 157%;
  color: #e9e9e9 !important;
}
.category-wrap .v-application--wrap .v-input__slot {
  padding-top: 0px !important;
}
.v_input-inner.full-category {
  width: 100%;
  display: block;
  background: rgba(37, 37, 37, 0.9) !important;
  border-top-left-radius: 20px;
  font-family: "Poppins";
}

/* .category-wrap .v-application--wrap .v-input--selection-controls {
  width: 50%;
} */

.category-wrap .v-application--wrap .v-input__slot {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  padding-right: 20px;
  padding-top: 20px;
}

.category-wrap .v-application--wrap .v-input--selection-controls__input {
  order: 2;
}

.category-wrap .v-application--wrap .v-input__slot label.v-label.theme--light {
  margin: 0;
  left: 0px;
  right: auto;
  position: relative;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: #e9e9e9 !important;
}
.category-wrap .v-application--wrap div {
  margin-left: 0px !important;
  padding-left: 0px !important;
}
/* .category-wrap .primary--text .theme--light.v-input--switch .v-input--switch__track {
  background: linear-gradient(90deg, #A00000 0%, #500000 102.31%) !important; 
 } */
.category-section
  .v-application--wrap
  .switch-on
  .v-input__control
  .v-input__slot
  .v-input--selection-controls__input
  .v-input--switch__track {
  background: linear-gradient(90deg, #368f17 0%, #90c639 102.31%);
  width: 50px !important;
  opacity: 1 !important;
}
.category-section
  .v-application--wrap
  .switch-off
  .v-input__control
  .v-input__slot
  .v-input--selection-controls__input
  .v-input--switch__track {
  background: linear-gradient(90deg, #a00000 0%, #500000 102.31%) !important;
  width: 50px !important;
  opacity: 1 !important;
}
.category-section
  .v-application--wrap
  .switch-on
  .v-input__control
  .v-input__slot
  .v-input--selection-controls__input
  .v-input--switch__thumb {
  transform: translate(33px, 0) !important;
}
.category-section
  .v-application--wrap
  .location-on
  .v-input__control
  .v-input__slot
  .v-input--selection-controls__input
  .v-input--switch__track {
  background: linear-gradient(90deg, #368f17 0%, #90c639 102.31%);
  width: 50px !important;
  opacity: 1 !important;
}
.category-section
  .v-application--wrap
  .location-off
  .v-input__control
  .v-input__slot
  .v-input--selection-controls__input
  .v-input--switch__track {
  background: linear-gradient(90deg, #a00000 0%, #500000 102.31%) !important;
  width: 50px !important;
  opacity: 1 !important;
}
.category-section
  .v-application--wrap
  .location-on
  .v-input__control
  .v-input__slot
  .v-input--selection-controls__input
  .v-input--switch__thumb {
  transform: translate(33px, 0) !important;
}
@media (max-width: 767px) {
  .category-wrap .v-application--wrap .v-input__slot span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    /* width: 100px; */
    margin-right: 5px;
  }
  .content-div {
    margin-bottom: 50px;
  }
}

.category-wrap.v-application .primary--text {
  color: #3eb067 !important;
  caret-color: #3eb067 !important;
  /* height: 18px; */
  top: 0;
}

.category-wrap .v-input--switch__thumb.theme--light.primary--text {
  background: #fff;
  height: 14px;
  width: 14px;
  top: 2px;
  right: -2px;
}

.category-wrap .v-input--switch__track.theme--light {
  height: 18px;
  top: 0;
}

.category-wrap .v-input--selection-controls__ripple {
  width: 14px;
  height: 14px;
}

.category-wrap .v-input--switch__thumb.theme--light {
  height: 14px;
  width: 14px;
  left: 3px;
}

.category-wrap
  .v-input--selection-controls__input:hover
  .v-input--selection-controls__ripple:before {
  display: none;
}

.category-wrap .v-input--selection-controls__ripple:before {
  opacity: 0;
}

.category-wrap .v-input--selection-controls__ripple {
  opacity: 0;
}

.chat-notification-dropdown button {
  padding: 0px;
}

.chat-notification-dropdown button .button-container {
  display: flex;
  gap: 8px;
}

.chat-notification div:first-child {
  display: flex;
}

.chat-notification .request-count {
  line-height: 1.1;
}

.request-button {
  border-radius: 6px;
  color: white;
  font-size: 13px;
  height: 30px;
  padding: 0px 6px !important;
}

.request-button.accept {
  border: 1px solid #0c910c;
  background: green;
}

.request-button.accept:hover {
  background: #67d100;
  border: 1px solid #67d100;
}

.request-button.reject {
  border: 1px solid #910c0c;
  background: rgb(128, 0, 0);
}

.request-button.reject:hover {
  background: #d10000;
  border: 1px solid #d10000;
}

.request-list-item {
  color: black;
  width: 100%;
  justify-content: space-between;
  width: 100%;
  display: flex;
  color: black;
  gap: 8px;
}

.request-list-item .actions {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 35px;
}

.blacktext {
  color: black;
}
@media (max-width: 390px) {
  .navbar a {
    font-size: 20px;
  }
}

.message-btn {
  position: relative;
  top: 1vh;
}

/* ends */
