.searchSeaction{
    position: fixed;
}
.marker {
    position: 'absolute';
    width: '30px';
    height: '30px';
    left: -30 / 2;
    top: -30 / 2;
    border: '5px solid #f44336';
    border-radius: '30px';
    background-color: 'white';
    text-align: 'center';
    color: '#3f51b5';
    font-size: 16;
    font-weight: 'bold';
    padding: 4;
  
}

.gm-ui-hover-effect span{
    background: white;
}

.gm-style-iw {
    /* min-width: 180px !important; */
    min-height: 0;
    max-height: 140px !important;
}

.banner-img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}
/* .mapContainer .drawer-section:first-child {
    position: absolute !important;
} */
/* .mapContainer div:first-child {
    position: relative !important;
} */
.roleDrawer {
    height: 80vh !important;
    top: 20vh !important;
    overflow: auto;
    background: black !important;
    opacity: 0.7;
    padding: 10px;
    border-radius: 25px;
    color: #e9e9e9!important;
    font-style: normal;
    font-weight: 400!important;
    font-size: 20px!important;
    line-height: 157%;
    /* position: relative; */
    width: 20%;
    .EZDrawer__container roleDrawer {
        background-color: black;
    }
    .role-list {
        /* margin-top: 185px; */
    }

}
.infoBox {
    position: relative;
    background-color: red;
    height: 100px;
    width: 100px;
    z-index: 9999999999;

}










.abc{
   display: flex;
   justify-content: space-between;
   font-size: 10px !important;
   margin-top: 5px !important;
   font-size: 15px;
}
.abc>span{
    text-align: left;
}
.abc>switch{
    text-align: right;
}
.search-btn{
    padding: 5px;
    margin: 6px;
    margin-top: 0;
    border-radius: 20% !important;
}
.toggel-btn{
   margin: 5px;
}
.gm-style{
 text-align: left;
 font-size: 10px;
 
}

.map-profile{
    width: auto;
    padding: 5px;
   
}

.map-profile span{
    font-size: 18px !important;
    color: white !important;
}

.map-profile p{
    color: white;
    font-size: 16px;
    margin-left: 1.2rem;
}

.roleIcons{
    margin-left: 1rem !important;
    height: 20px;

}

.img-right2{
    margin-left: 10px;
}
.map-img{
    
    display: flex;
    justify-content: end;
}
.map-img img{
    height: 30px;
}
/* .img-right{
  display: inline;
   margin-left: 80%; 
}
.img-right2{
 display: inline;
    margin-left: 90%; */
/* } */
.gm-style-iw button.gm-ui-hover-effect {
    
    color: #FFFFFF !important; /* Change to the desired text color */
  }

.gm-style .gm-style-iw-c{
  padding: 5px;
  color: #fff;
  background-color: #313131; 
  opacity: .8; 
   
}
.gm-style .gm-style-iw-c>button{
    color: #fff;
}
.gm-style-iw-d{
  overflow: hidden !important;
  position: relative;
  top: -40px;
  display: inline-table !important;
}


.infoWindow {
    max-width: 300px;
    max-height: 234px;
    min-width: 300px;
    min-height: 114px;
}
.react-switch-bg{
    border: 1px solid white;
    background: black !important;
}
/*#map {
    background-color: red;
    
    

  
    .gm-style {
        img {
            
                width: 30px !important;
                height: 30px !important;
            
        }

    }  */

/* } */
