.profile-page {
    // background-color: #252525;
    background-color: #000000;
    margin-top: 1.5rem;
    .banner-row{
        margin: 0 !important;
        // background-color: #121212;
        display: block !important;
        --bs-gutter-x: 0 !important;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .profile-container {
        padding: 0 3rem;
        .profile-info-container {
            display: flex;
            align-items: flex-end;
            width: 50%;
            // background-color: white;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

            .profile-picture {
                width: 13rem;
                height: 13rem;
                border-radius: 50%;
                margin-right: 20px;
                margin-top: -2rem;
                overflow: hidden;
            }

            .profile-picture img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .profile-info {
                color: white;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                position: relative;
                margin-top: 90px;
            }

            .profile-info-item {
                margin-bottom: 5px;
            }
        }


    }

    // height: 100%;
    .banner-header {
        background: url(http://lorempixel.com/850/280/nature/4);
        background-size: cover;
        height: 250px;
        font-family: Roboto, sans-serif !important;

        .banner-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px !important;
        }
    }

    .profile-row {
        position: relative;
        // display: flex;
        margin-top: -170px;
        margin-bottom: -50px;
        -o-object-fit: cover;
        object-fit: cover;
        top: unset;
        left: unset;

        .profile-img {
            width: 150px;
            height: 150px;
            max-width: 150px;
            max-height: 150px;
            border-radius: 50%;
            border: 5px solid #f8f8f8;
        }
    }

    .share-btn {
        font-size: 25px;
    }

    .username {
        color: #ffff;
        font-size: 20px;
    }

    .title {
        font-size: 50px;
        font-weight: bolder;
    }

    .role-item {
        border: 1px solid #fff;
        padding: 5px 15px 5px 15px;
        border-radius: 5px;
        font-size: 20px;
        color: #9fa3a7;
    }

    .desc-friends {
        margin-top: 20px;
        font-size: 25px;
        line-height: 20px;
        color: #737373;
        text-overflow: ellipsis;
    }

    .compass {
        font-size: 25px;
    }

    .card {
        padding-top: 20px;
        background-color: rgba(214, 224, 226, 0.2);
        border-top-width: 0;
        border-bottom-width: 2px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .tile {
        width: 350px;
        min-height: 350px;
    } 

    .button-container {
        margin-top: -6rem;
        text-align: right;
      }
    
      .button-container button {
        background-color: black;
        color: white;
        border: 2px solid #C29B0C;
        border-radius: 5px;
        padding: 10px 20px;
        margin-left: 10px; /* Adjust as needed */
        cursor: pointer;
        transition: background-color 0.3s;
      }
    
      
    button:hover {
        background-color: #C29B0C;
      }
}

.bold{
    font-weight: 700;
}

.font-xl{
    font-size: 1.5rem;;
}

.mt-md{
    margin-top: 8rem !important;
}

.profile-content-container{
    // padding: 0 3rem;
    // background-color: white;
}


