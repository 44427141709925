.active-menu {
    background-color: #ff4d1d !important;
    color: #fff !important;

}
.margin-15{
    margin-bottom: 20px;
}

.margin{
    margin-left: 30px;
}
.padding{
    padding: 0;
}

.input-area{
    padding: 10px;
    margin: 10px;
}