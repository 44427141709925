@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap");


.home-page {
    font-family: 'Roboto';
    .heading-6 {
        padding-bottom: 75px;
        span {
            color: #e07300;
            font-weight: 700;
        }
    }
    .has-white-color {
        color: #FFFFFF;
    }
    img {
        width: 100%;
        object-fit: contain;
    }
}

.homepage-container{
    margin-top: 2rem;
    p{

        color: white;
        font-weight: 600;
    }
}
