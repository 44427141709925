

    .massage-pannel {
      display: flex;
      align-items: center;
      background-color: #1c1c1d;
      width: 100%;
      padding: 10px;
    }
    .emoji i,
    .file-send-btn i {
      font-size: 25px;
      color: #fff;
    }
    
    .icon {
     
      margin-right: 15px; 
      
    }
    .send-icon{
      margin-right: 200px;
      font-size: 20px;
      color: #f9f9f9;
      background-color: #1c1c1d;
      border: none;
    }
    .input-fild {
      flex: 1; 
      width: 250px; 
      color: #ffffff;
      background-color:#1c1c1d ;
      border: none;
      outline: none;
      margin-right: 10px;
    }
    
    .input {
      display: flex;
      align-items: center;
    }
  
    .search-bar{
      color: #fff;
      background-color: rgba(50,50,50,.51);
      border: none;
      outline: none;
      border-radius: 0 10% 10% 0;
    }
    .search-icon-containor{
      background-color: rgba(50,50,50,.51) !important;
      padding: 6px;
      border-radius: 10% 0 0 10%;
    }
    
    .search-icon{
      color: #fff;
      // border-radius: -;
    }
  
    .media-icon{
      padding: 10px;
      height: 65px;
      margin-bottom: 0 !important;
    }
    .copy-btn{
      border: none;
      outline: none;
      background-color: #fff;
      font-size: 18px;
    }
    
.chat-container {
       
          height: calc(100vh - 145px);
          background: radial-gradient(100% 100% at 51.07% 100%,rgba(22,9,50,0) 0,rgba(45,105,131,.5) 100%);
          border-radius: 5px;
}
.friends-info-block {
          background: rgba(7,22,26,.7);
          border: 2px solid #e9e9e9;
          border-radius: 8px;
          .icon-img {
              width: 17px;
              height: 17px;
            }
            .username {
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 157%;
              color: #e9e9e9;
            }
            .desc-role {
              font-style: normal;
              font-weight: 300;
              font-size: 11px;
              line-height: 157%;
              text-align: left;
              color: rgba(233, 233, 233, 0.7);
            }
            .read-time {
              font-style: normal;
              font-weight: 300;
              font-size: 12px;
              line-height: 157%;
              /* or 14px */
              color: #ffffff;
            }
         
         
}
  
  

.chat-window {
  float: right;
  position: relative;
  height: calc(100% - 11px);
  width: 50%;
    .chat-box-header {
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    background: linear-gradient(270deg,rgba(22,9,50,.5) 13.89%,rgba(0,209,255,.14) 49.79%,rgba(22,9,50,.5) 88.76%);
    height: 96px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #c8c8c8;
    text-align: center;
    font-size: 20px;
    padding: 10px;
    display: flex;
    }
    img.friend-profile.m-2 {
      margin-left: 20px !important;
      margin-top: 60px;
    }
    .user-name {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #e9e9e9;
        font-weight: 700;
        margin-left: 20px;
    }
    .user-name.col{
      margin-right: 300px !important;
    }
    .chat-box-body-ful {
      height: 45vh;
      overflow-y: scroll;
      overflow-x: hidden;
      scroll-behavior: smooth;
      background-color: #2b2828;
    }
}
.chat-msg {
    clear: both;
}
.self {
    float: right;
}
  
.user {
    float: left
}
  
.chat-msg.self>.cm-msg-text-full {
    float: right;
    margin-right: 10px;
    font-family: sans-serif;
    background: rgba(0,128,190,.8);
    border-radius: 23px;
    color: #fff;
    padding: 10px 15px 10px 15px;
    
    position: relative;
    margin-bottom: 20px;
    border-radius: 30px;
}
  
  
.chat-msg.user>.cm-msg-text-full {
    background: rgba(233,103,36,.8);
    border-radius: 23px;
    padding: 10px 15px 10px 15px;
    color: #f9f9f9;
    max-width: 75%;
    float: left;
    margin-left: 10px;
    position: relative;
    margin-bottom: 20px;
    border-radius: 30px;
}

.chat-request-container {
  color: #fff;
  text-align: center;
}
  