@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.wrapper {
  padding: 100px;
  color: var(--text-color-primary);
}
.whitetext{
  color: #fff;
}
.balanceDiv {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  font-size: 22px;
}
.balanceDiv img {
  height: 35px;
}
.wallet-info {
  color: #21EF00;
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 600;

}
.service-btn {
    background: linear-gradient(260.02deg, #0097FF 2.67%, #006BFF 33.44%, #003885 92.93%) !important;
    color: #FFFFFF;
    border-radius: 17px;
    padding: 50px 0px 50px 0px;
  }
  .service-btn .label-text {
    font-size: 36px;
    font-family: 'Michroma', sans-serif;
    text-shadow: 2px 0 #00000040, -2px 0 #00000040, 0 2px #00000040, 0 -2px #00000040,
               1px 1px #00000040, -1px -1px #00000040, 1px -1px #00000040, -1px 1px #00000040;
  }
  .service-btn img {
    width: 60px;
    height: 60px;
  }
.disconnectBtn {
  justify-content: end;
  border-radius: 70px;
  padding: 8px 30px;
  font-size: 22px;
  font-weight: 900;
  border: none;
  font-family: "Roboto", sans-serif !important;
  float: right;
  margin: 25px;
}
.id {
  font-size: 22px;
}
@media (max-width: 767px) {
  .wrapper {
    justify-content: center;
    align-items: center;
  }
  .id {
    font-size: 11px;
    margin-left: -17px;
  }
  .balanceDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin-left: -14px;
  }

  .balanceDiv > img {
    height: 30px;
  }
  .disconnectBtn {
    align-items: center;
    text-align: center;
    align-content: center;
    float: none;
  }
}
  @media (max-width: 420px) {
    .xumm-icon {
      width: 220px;
    }
    .xumm-btn {
      margin-top: 160px !important;
    }
    .service-btn .label-text {
      font-size: 24px;
    }
    .wrapper {
      padding: 50px;
    }
    .wallet-info {
      font-size: 14px;
    }
  }